import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Auth from './screens/Auth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from './screens/ForgotPassword';
import ChangePassword from './screens/ChangePassword';

import HelpCenter from './screens/media/HelpCenter';
import ManageFaq from './components/faq/ManageFaq';
import EditFaq from './screens/EditFaq';
import PrivacyScreen from './screens/PrivacyScreen';
import ViewPersonalProfileScreen from './screens/ViewPersonalProfileScreen';
import SeeAllTithesPaid from './screens/SeeAllTithesPaid';
import SeeAllOnlineTithesPaid from './screens/SeeAllOnlineTithesPaid';

import CreateSermon from './components/sermon/CreateSermon';
import EditSermon from './components/sermon/EditSermon';
import SliderScreen from './screens/SliderScreen';
import HomeCellScreen from './screens/HomeCellScreen';
import FlyerScreen from './screens/FlyerScreen';
import PrivateRoute from './components/routes/PrivateRoutes';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux'


import { useGetUserQuery } from './redux/features/api/apiSlice';
import { userLoggedIn } from './redux/features/auth/authSlice';
import UserPage from './screens/adminPages/UserPage';
import MediaSharedLayout from './screens/MediaSharedLayout';
import LiveProgramScreen from './screens/media/LiveProgramScreen';
import SalvationTv from './screens/media/SalvationTv';
import SermonScreen from './screens/media/SermonScreen';
import ProfileSetup from './screens/media/ProfileSetup';
import ProfileScreen from './screens/media/ProfileScreen';
import BroadCastMessage from './screens/media/BroadCastMessage';
import CountryManagementPage from './screens/media/CountryManagementPage';
import CountryDetailPage from './screens/media/CountryDetailPage';
import CountryUpdatePage from './screens/media/CountryUpdatePage';
import CountryFormPage from './screens/media/CountryFormPage';
import WelcomeScreen from './screens/WelcomeScreen';
import IsNotLoginAuth from './components/routes/IsNotLoginAuth';
import Loader from './components/loader/Loader';
import DeleteAccount from './screens/DeleteAccount';
import PrivacyPage from './screens/PrivacyPage';


function App() {
  const dispatch = useDispatch();
  const { data: user, isLoading } = useGetUserQuery();

  useEffect(() => {
    if (user) {
      dispatch(userLoggedIn({ user, accessToken: localStorage.getItem('accessToken') || '' }));
      // Reload the page
    }
  }, [user, dispatch]);

  if (isLoading) {
    return <div>
      <Loader />
    </div>;
  }

  return (
    <BrowserRouter>
      <Routes>
        {/* Catch-all route to redirect to the appropriate dashboard or login page */}
        <Route path="*" element={<Navigate to={user ? `/admin` : '/'} replace />} />
        <Route element={<IsNotLoginAuth />}>
          <Route path="/" element={<WelcomeScreen />} />
        </Route>
        <Route element={<IsNotLoginAuth />}>
          <Route path="/sign-in" element={<Auth />} />
        </Route>

        <Route element={<IsNotLoginAuth />}>
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Route>
        <Route element={<IsNotLoginAuth />}>
          <Route path="/reset-password" element={<ChangePassword />} />
        </Route>
        <Route path="/delete-account" element={<DeleteAccount />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />


        <Route element={<PrivateRoute allowedRoles={['admin']} />}>
          <Route path="/admin" element={<MediaSharedLayout />}>
            <Route index element={<LiveProgramScreen />} />
            <Route path="manage-slider" element={<SliderScreen />} />
            <Route path="manage-flyer" element={<FlyerScreen />} />
            <Route path="manage-tv" element={<SalvationTv />} />
            <Route path="sermon" element={<SermonScreen />} />
            <Route path='create-sermon' element={<CreateSermon />} />
            <Route path='edit-sermon/:id' element={<EditSermon />} />
            <Route path="broadcast" element={<BroadCastMessage />} />
            <Route path="help-center" element={<HelpCenter />} />
            <Route path="edit-faq/:id" element={<EditFaq />} />
            <Route path="manage-faq" element={<ManageFaq />} />
            <Route path="view-profile/:id" element={<ViewPersonalProfileScreen />} />
            <Route path='all-tithe-paid-online/:id' element={<SeeAllOnlineTithesPaid />} />
            <Route path='all-tithe-paid/:id' element={<SeeAllTithesPaid />} />
            <Route path="all-users" element={<UserPage />} />
            <Route path="piracy" element={<PrivacyScreen />} />
            <Route path="profile-setup" element={<ProfileSetup />} />
            <Route path="location" element={<CountryManagementPage />} />
            <Route path="add-country" element={<CountryFormPage />} />
            <Route path="location/:id" element={<CountryDetailPage />} />
            <Route path="country-edit/:id" element={<CountryUpdatePage />} />
            <Route path="homecell-screen" element={<HomeCellScreen />} />
            <Route path="settings" element={<ProfileScreen />} />
          </Route>
        </Route>

      </Routes>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </BrowserRouter>
  );
}

export default App;
