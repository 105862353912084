

export default function PrivacyPage() {
  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:p-6">
          <h1 className="text-3xl font-bold text-gray-900 mb-6">Privacy Policy</h1>
          
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-3">1. Introduction</h2>
            <p className="text-gray-600 mb-2">
              Welcome to SMHOS App. We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our application, including when you make donations through our platform.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-3">2. Information We Collect</h2>
            <p className="text-gray-600 mb-2">
              We collect personal information that you provide to us such as name, email address, and contact information. When you make donations, we may collect additional information such as payment details. We may also collect information automatically when you use the App, including usage data and device information.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-3">3. How We Use Your Information</h2>
            <p className="text-gray-600 mb-2">
              We use your information to provide and improve our services, process donations, communicate with you, and comply with legal obligations. We may also use your information for marketing purposes with your consent.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-3">4. Donations and Payment Processing</h2>
            <p className="text-gray-600 mb-2">
              Our app facilitates various types of donations, including offerings, tithes, and sacrificial giving. To process these donations, we use Flutterwave, a third-party payment gateway.
            </p>
            <p className="text-gray-600 mb-2">
              When you make a donation, you will be redirected to Flutterwave's secure payment platform. The information you provide during the transaction (such as credit card details) is collected and processed directly by Flutterwave. We do not store your full payment information on our servers.
            </p>
            <p className="text-gray-600 mb-2">
              Please note that Flutterwave has its own privacy policy and terms of service. We encourage you to review these before proceeding with any transaction.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-3">5. Sharing Your Information</h2>
            <p className="text-gray-600 mb-2">
              We may share your information with third-party service providers, including Flutterwave for payment processing, business partners, and affiliated companies. We do not sell your personal information to third parties.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-3">6. Data Security</h2>
            <p className="text-gray-600 mb-2">
              We implement appropriate technical and organizational measures to protect your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure. For payment transactions, we rely on Flutterwave's security measures to protect your financial information.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-3">7. Your Rights</h2>
            <p className="text-gray-600 mb-2">
              You have the right to access, correct, or delete your personal information. You may also have the right to restrict or object to certain processing of your data. These rights may be limited in some circumstances by local law requirements.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-3">8. Changes to This Privacy Policy</h2>
            <p className="text-gray-600 mb-2">
              We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-3">9. Contact Us</h2>
            <p className="text-gray-600 mb-2">
              If you have any questions about this Privacy Policy, including any requests to exercise your legal rights, please contact us at:
            </p>
            <p className="text-gray-800 font-medium">
              Email: info@smhos.org<br />
              Address: Plot 17 Birabi Street, GRA Phase One, Port Harcourt, Rivers State
            </p>
          </section>

          <div className="mt-8 pt-8 border-t border-gray-200">
            <p className="text-sm text-gray-500">
              Last Updated: {new Date().toLocaleDateString()}
            </p>
          </div>
        </div>
        <div className="px-4 py-4 sm:px-6 bg-gray-50">
          <a href="#" className="text-sm font-medium text-blue-600 hover:text-blue-500">
            ← Back to Home
          </a>
        </div>
      </div>
    </div>
  )
}

