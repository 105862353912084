import SermonLayout from "../../components/sermon/SermonLayout"


type Props = {}

export default function SermonScreen({ }: Props) {
  return (
    <>

      <SermonLayout />

    </>
  )
}