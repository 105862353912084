import HomeCellAdmin from '../components/homecell/HomeCellAdmin'

type Props = {}

export default function HomeCellScreen({ }: Props) {
  return (
    <>

      <HomeCellAdmin />

    </>
  )
}