import InformationCenter from '../../components/informationCenter/InformationCenter'

type Props = {}

export default function HelpCenter({ }: Props) {

  return (
    <>
      <InformationCenter />
    </>
  )
}