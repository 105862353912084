import BroadCastLayout from "../../components/broadcast/BroadCastLayout"

type Props = {}

export default function BroadCastMessage({ }: Props) {

    return (
        <>

            <BroadCastLayout />

        </>
    )
}