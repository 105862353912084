import LiveProgramLayout from '../../components/liveProgram/LiveProgramLayout'


type Props = {}

export default function LiveProgramScreen({ }: Props) {
  return (
    <>
      <LiveProgramLayout />
    </>
  )
}