'use client'

import { useState, useEffect } from 'react'

export default function DeleteContact() {
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)

  // Email validation function
  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return re.test(String(email).toLowerCase())
  }

  // Update email validity whenever the email changes
  useEffect(() => {
    setIsEmailValid(validateEmail(email))
  }, [email])

  const handleDelete = () => {
    if (!isEmailValid) return

    setIsLoading(true)
    // Simulate API call
    setTimeout(() => {
      setIsLoading(false)
      setIsSuccess(true)
      setShowConfirmation(false)
    }, 2000)
  }

  if (isSuccess) {
    return (
      <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-2">Account Deleted</h2>
        <p className="text-gray-600">Your contact has been successfully removed from SMHOS App.</p>
      </div>
    )
  }

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-2">Delete Contact</h2>
      <p className="text-gray-600 mb-4">Remove your contact from SMHOS App</p>
      <div className="mb-4">
        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
          Email
        </label>
        <input
          type="email"
          id="email"
          className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${
            email && !isEmailValid ? 'border-red-500' : 'border-gray-300'
          }`}
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {email && !isEmailValid && (
          <p className="mt-1 text-sm text-red-600">Please enter a valid email address.</p>
        )}
      </div>
      <button
        className={`w-full py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${
          isEmailValid
            ? 'bg-red-600 text-white hover:bg-red-700 focus:ring-red-500'
            : 'bg-gray-300 text-gray-500 cursor-not-allowed'
        }`}
        onClick={() => isEmailValid && setShowConfirmation(true)}
        disabled={!isEmailValid}
      >
        Delete Contact
      </button>

      {showConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg max-w-sm w-full">
            <h3 className="text-lg font-semibold mb-2">Are you absolutely sure?</h3>
            <p className="text-gray-600 mb-4">
              This action cannot be undone. This will permanently delete your
              contact from our servers.
            </p>
            <div className="flex justify-end space-x-2">
              <button
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                onClick={() => setShowConfirmation(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                onClick={handleDelete}
                disabled={isLoading}
              >
                {isLoading ? "Deleting..." : "Yes, delete my contact"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

