import Privacy from '../components/privacy/Privacy'

type Props = {}

export default function PrivacyScreen({ }: Props) {
  return (
    <>
      <Privacy />
    </>
  )
}